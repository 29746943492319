import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueRouter);

function checkSecretKey(to, from, next) {
    const encodedString = localStorage.getItem("sign-in");

    console.log(encodedString + "here");

    if (!encodedString) {
        next("/sign-in");
        return;
    }

    const decodedString = atob(encodedString);
    if (decodedString && decodedString.includes("your_secret_key")) {
        next();
    } else {
        next("/sign-in");
    }
}

let routes = [
    {
        // will match everything
        path: "*",
        component: () => import("../views/404.vue"),
    },
    {
        path: "/",
        name: "Home",
        redirect: "/dashboards/",
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: "Login",
        },
        component: () => import("../views/Authentication/Sign-in/SignIn.vue"),
    },
    {
        path: "/dashboards/",
        name: "Dashboard",
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: "Default Dashboard",
            sidebarMap: ["dashboards"],
            breadcrumbs: ["Dashboards", "Default"],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboards/Default.vue"),
    },
    {
        path: "/dashboards/crm",
        name: "DashboardsCRM",
        layout: "dashboard",
        meta: {
            title: "CRM",
            sidebarMap: ["dashboards"],
            breadcrumbs: ["Dashboards", "CRM"],
        },
        component: () => import("../views/Dashboards/CRM.vue"),
    },
    {
        path: "/users/edit/:id",
        name: "Edit User",
        layout: "dashboard",
        meta: {
            title: "Edit User",
            sidebarMap: ["users", "edit-user"],
            breadcrumbs: ["Users", "Edit User"],
        },
        component: () => import("../views/Users/User.vue"),
    },
    {
        path: "/admins/edit/:id",
        name: "Edit Admin",
        layout: "dashboard",
        meta: {
            title: "Edit Admin",
            sidebarMap: ["admins", "edit-admin"],
            breadcrumbs: ["Admins", "Edit Admin"],
        },
        component: () => import("../views/Admins/Admin.vue"),
    },
    {
        path: "/users/list",
        name: "Users List",
        layout: "dashboard",
        meta: {
            title: "Users List",
            sidebarMap: ["users", "users-list"],
            breadcrumbs: ["Users", "Users List"],
        },
        component: () => import("../views/Users/Users.vue"),
    },
    {
        path: "/admins/list",
        name: "Admins List",
        layout: "dashboard",
        meta: {
            title: "Admins List",
            sidebarMap: ["admins", "admins-list"],
            breadcrumbs: ["Admins", "Admins List"],
        },
        component: () => import("../views/Admins/AdminsList.vue"),
    },
    {
        path: "/admins/create",
        name: "Create Admin",
        layout: "dashboard",
        meta: {
            title: "Create Admin",
            sidebarMap: ["admins", "admin"],
            breadcrumbs: ["Admins", "Create Admin"],
        },
        component: () => import("../views/Admins/Admin.vue"),
    },
    {
        path: "/reports/reports-list",
        name: "Reports",
        layout: "dashboard",
        meta: {
            title: "Reports List",
            sidebarMap: ["reports", "reports-list"],
            breadcrumbs: ["Reports", "List"]
        },
        component: () => import("../views/Reports/ReportsList.vue"),
    },
    {
        path: "/reports/show/:id",
        name: "Show Report",
        layout: "dashboard",
        meta: {
            title: "Show Report",
            sidebarMap: ["reports", "show"],
            breadcrumbs: ["Reports", "Show"],
        },
        component: () => import("../views/Reports/Report.vue"),
    },
    {
        path: "/mentions/edit/:id",
        name: "Edit Mention",
        layout: "dashboard",
        meta: {
            title: "Edit Mention",
            sidebarMap: ["mentions", "edit-mention"],
            breadcrumbs: ["Mentions", "Edit Mention"],
        },
        component: () => import("../views/Mentions/EditMention.vue"),
    },
    {
        path: "/mentions/create",
        name: "Create Mention",
        layout: "dashboard",
        meta: {
            title: "Create Mention",
            sidebarMap: ["mentions", "mention"],
            breadcrumbs: ["Mentions", "Create Mention"],
        },
        component: () => import("../views/Mentions/EditMention.vue"),
    },
    {
        path: "/mentions/mentions-list",
        name: "Mentions List",
        layout: "dashboard",
        meta: {
            title: "Mentions List",
            sidebarMap: ["mentions", "mentions-list"],
            breadcrumbs: ["Mentions", "Mentions List"],
        },
        component: () => import("../views/Mentions/MentionsList.vue"),
    },
    {
        path: "/guides/edit/:id",
        name: "Edit Guide",
        layout: "dashboard",
        meta: {
            title: "Edit Guide",
            sidebarMap: ["guides", "edit-guides"],
            breadcrumbs: ["Guides", "Edit Guide"],
        },
        component: () => import("../views/Guides/EditGuide.vue"),
    },
    {
        path: "/guides/create",
        name: "Create Guide",
        layout: "dashboard",
        meta: {
            title: "Create Guide",
            sidebarMap: ["guides", "guide"],
            breadcrumbs: ["Guides", "Create Guide"],
        },
        component: () => import("../views/Guides/EditGuide.vue"),
    },
    {
        path: "/guides/guides-list",
        name: "Guides List",
        layout: "dashboard",
        meta: {
            title: "Guides List",
            sidebarMap: ["guides", "guides-list"],
            breadcrumbs: ["Guides", "Guides List"],
        },
        component: () => import("../views/Guides/GuidesList.vue"),
    },
    {
        path: "/places/edit/:id",
        name: "Edit Place",
        layout: "dashboard",
        meta: {
            title: "Edit Place",
            sidebarMap: ["places", "edit-place"],
            breadcrumbs: ["Places", "Edit Place"],
        },
        component: () => import("../views/Places/EditPlace.vue"),
    },
    {
        path: "/places/create",
        name: "Create Place",
        layout: "dashboard",
        meta: {
            title: "Create Place",
            sidebarMap: ["places", "place"],
            breadcrumbs: ["Places", "Create Place"],
        },
        component: () => import("../views/Places/EditPlace.vue"),
    },
    {
        path: "/places/places-list",
        name: "Places List",
        layout: "dashboard",
        meta: {
            title: "Places List",
            sidebarMap: ["places", "places-list"],
            breadcrumbs: ["Places", "Places List"],
        },
        component: () => import("../views/Places/PlacesList.vue"),
    },
    {
        path: "/cities/edit/:id",
        name: "Edit City",
        layout: "dashboard",
        meta: {
            title: "Edit City",
            sidebarMap: ["cities", "edit-city"],
            breadcrumbs: ["Cities", "Edit City"],
        },
        component: () => import("../views/Cities/EditCity.vue"),
    },
    {
        path: "/cities/create",
        name: "Create City",
        layout: "dashboard",
        meta: {
            title: "Create City",
            sidebarMap: ["cities", "city"],
            breadcrumbs: ["Cities", "Create City"],
        },
        component: () => import("../views/Cities/EditCity.vue"),
    },
    {
        path: "/cities/cities-list",
        name: "Cities List",
        layout: "dashboard",
        meta: {
            title: "Cites List",
            sidebarMap: ["cites", "cities-list"],
            breadcrumbs: ["Cities", "Cities List"],
        },
        component: () => import("../views/Cities/CitiesList.vue"),
    },
    {
        path: "/transportations/edit/:id",
        name: "Edit Transportation",
        layout: "dashboard",
        meta: {
            title: "Edit Transportation",
            sidebarMap: ["transportations", "edit-transportation"],
            breadcrumbs: ["Transportations", "Edit Transportation"],
        },
        component: () => import("../views/Transportations/EditTransportation.vue"),
    },
    {
        path: "/transportations/create",
        name: "Create Transportation",
        layout: "dashboard",
        meta: {
            title: "Create Transportation",
            sidebarMap: ["transportations", "transportation"],
            breadcrumbs: ["Transportations", "Create Transportation"],
        },
        component: () => import("../views/Transportations/EditTransportation.vue"),
    },
    {
        path: "/transportations/transportations-list",
        name: "Transportations List",
        layout: "dashboard",
        meta: {
            title: "Transportations List",
            sidebarMap: ["transportations", "transportations-list"],
            breadcrumbs: ["Transportations", "Transportations List"],
        },
        component: () => import("../views/Transportations/TransportationsList.vue"),
    },
    {
        path: "/companions/edit/:id",
        name: "Edit Companion",
        layout: "dashboard",
        meta: {
            title: "Edit Companion",
            sidebarMap: ["companions", "edit-companion"],
            breadcrumbs: ["Companions", "Edit Companion"],
        },
        component: () => import("../views/Companions/EditCompanion.vue"),
    },
    {
        path: "/companions/create",
        name: "Create Companion",
        layout: "dashboard",
        meta: {
            title: "Create Companion",
            sidebarMap: ["companions", "companion"],
            breadcrumbs: ["Companions", "Create Companion"],
        },
        component: () => import("../views/Companions/EditCompanion.vue"),
    },
    {
        path: "/companions/companions-list",
        name: "Companions List",
        layout: "dashboard",
        meta: {
            title: "Companions List",
            sidebarMap: ["companions", "companions-list"],
            breadcrumbs: ["Companions", "Companions List"],
        },
        component: () => import("../views/Companions/CompanionsList.vue"),
    },
    {
        path: "/ready-other-places/edit/:id",
        name: "Edit Ready Other Place",
        layout: "dashboard",
        meta: {
            title: "Edit Ready Other Place",
            sidebarMap: ["ready-other-places", "edit-ready-other-places"],
            breadcrumbs: ["ReadyOtherPlace", "Edit Ready Other Place"],
        },
        component: () => import("../views/ReadyOtherPlaces/EditReadyOtherPlace.vue"),
    },
    {
        path: "/ready-other-places/create",
        name: "Create Ready Other Place",
        layout: "dashboard",
        meta: {
            title: "Create Ready Other Place",
            sidebarMap: ["ready-other-places", "ready-other-places"],
            breadcrumbs: ["ReadyOtherPlace", "Create Ready Other Place"],
        },
        component: () => import("../views/ReadyOtherPlaces/EditReadyOtherPlace.vue"),
    },
    {
        path: "/ready-other-places/ready-other-places-list",
        name: "Ready Other Place List",
        layout: "dashboard",
        meta: {
            title: "Ready Other Place List",
            sidebarMap: ["ready-other-places", "ready-other-places-list"],
            breadcrumbs: ["ReadyOtherPlace", "Ready Other Place List"],
        },
        component: () => import("../views/ReadyOtherPlaces/ReadyOtherPlacesList.vue"),
    },
    {
        path: "/places-to-stay-answers/edit/:id",
        name: "Edit Places To Stay",
        layout: "dashboard",
        meta: {
            title: "Edit Places To Stay",
            sidebarMap: ["places-to-stay-answers", "edit-places-to-stay-answers"],
            breadcrumbs: ["PlacesToStayAnswer", "Edit Places To Stay"],
        },
        component: () => import("../views/PlacesToStayAnswers/EditPlacesToStayAnswer.vue"),
    },
    {
        path: "/places-to-stay-answers/create",
        name: "Create Places To Stay",
        layout: "dashboard",
        meta: {
            title: "Create Places To Stay",
            sidebarMap: ["places-to-stay-answers", "places-to-stay-answers"],
            breadcrumbs: ["PlacesToStayAnswer", "Create Places To Stay"],
        },
        component: () => import("../views/PlacesToStayAnswers/EditPlacesToStayAnswer.vue"),
    },
    {
        path: "/places-to-stay-answers/places-to-stay-answers-list",
        name: "Places To Stay List",
        layout: "dashboard",
        meta: {
            title: "Places To Stay List",
            sidebarMap: ["places-to-stay-answers", "places-to-stay-answers-list"],
            breadcrumbs: ["PlacesToStayAnswer", "Places To Stay List"],
        },
        component: () => import("../views/PlacesToStayAnswers/PlacesToStayAnswersList.vue"),
    },
    {
        path: "/preferences/edit/:id",
        name: "Edit Preference",
        layout: "dashboard",
        meta: {
            title: "Edit City",
            sidebarMap: ["preferences", "edit-preference"],
            breadcrumbs: ["preferences", "Edit Preference"],
        },
        component: () => import("../views/Preferences/EditPreference.vue"),
    },
    {
        path: "/preferences/create",
        name: "Create Preference",
        layout: "dashboard",
        meta: {
            title: "Create Preference",
            sidebarMap: ["preferences", "preference"],
            breadcrumbs: ["preferences", "Create Preference"],
        },
        component: () => import("../views/Preferences/EditPreference.vue"),
    },
    {
        path: "/preferences/preferences-list",
        name: "Preferences List",
        layout: "dashboard",
        meta: {
            title: "Preferences List",
            sidebarMap: ["preferences", "preferences-list"],
            breadcrumbs: ["Preferences", "Preferences List"],
        },
        component: () => import("../views/Preferences/PreferencesList.vue"),
    },
    {
        path: "/categories/edit/:id",
        name: "Edit Category",
        layout: "dashboard",
        meta: {
            title: "Edit Category",
            sidebarMap: ["categories", "edit-category"],
            breadcrumbs: ["categories", "Edit Category"],
        },
        component: () => import("../views/Categories/EditCategory.vue"),
    },
    {
        path: "/categories/create",
        name: "Create Category",
        layout: "dashboard",
        meta: {
            title: "Create Category",
            sidebarMap: ["categories", "category"],
            breadcrumbs: ["categories", "Create Category"],
        },
        component: () => import("../views/Categories/EditCategory.vue"),
    },
    {
        path: "/categories/categories-list",
        name: "Categories List",
        layout: "dashboard",
        meta: {
            title: "Categories List",
            sidebarMap: ["categories", "categories-list"],
            breadcrumbs: ["Categories", "Categories List"],
        },
        component: () => import("../views/Categories/CategoriesList.vue"),
    },
    {
        path: "/subcategories/edit/:id",
        name: "Edit Subcategory",
        layout: "dashboard",
        meta: {
            title: "Edit Subcategory",
            sidebarMap: ["subcategories", "edit-subcategory"],
            breadcrumbs: ["subcategories", "Edit Subcategory"],
        },
        component: () => import("../views/Subcategories/EditSubcategory.vue"),
    },
    {
        path: "/subcategories/create",
        name: "Create Subcategory",
        layout: "dashboard",
        meta: {
            title: "Create Subcategory",
            sidebarMap: ["subcategories", "subcategory"],
            breadcrumbs: ["subcategories", "Create Subcategory"],
        },
        component: () => import("../views/Subcategories/EditSubcategory.vue"),
    },
    {
        path: "/subcategories/subcategories-list",
        name: "Subcategories List",
        layout: "dashboard",
        meta: {
            title: "Subcategories List",
            sidebarMap: ["subcategories", "subcategories-list"],
            breadcrumbs: ["Subcategories", "Subcategories List"],
        },
        component: () => import("../views/Subcategories/SubcategoriesList.vue"),
    },
    {
        path: "/ecommerce/orders/orders-details",
        name: "Orders Details",
        layout: "dashboard",
        meta: {
            title: "Orders Details",
            sidebarMap: ["ecommerce", "orders", "orders-details"],
            breadcrumbs: ["Ecommerce", "Orders", "Orders Details"],
        },
        component: () => import("../views/Ecommerce/Orders/OrdersDetails.vue"),
    },
    {
        path: "/authentication/sign-up/basic",
        name: "Basic Sign Up",
        meta: {
            layoutClass: "layout-sign-up",
            title: "Basic Sign Up",
            sidebarMap: ["authentication", "sign-up", "basic"],
            breadcrumbs: ["Authentication", "Sign Up", "Basic"],
        },
        component: () => import("../views/Authentication/Sign-Up/Basic.vue"),
    },
    {
        path: "/authentication/sign-up/cover",
        name: "Cover Sign Up",
        meta: {
            layoutClass: "layout-sign-up-cover",
            title: "Cover Sign Up",
            sidebarMap: ["authentication", "sign-up", "cover"],
            breadcrumbs: ["Authentication", "Sign Up", "Cover"],
        },
        component: () => import("../views/Authentication/Sign-Up/Cover.vue"),
    },
    {
        path: "/authentication/sign-up/illustration",
        name: "Illustration Sign Up",
        meta: {
            layoutClass: "layout-sign-up-illustration",
            title: "Illustration Sign Up",
            sidebarMap: ["authentication", "sign-up", "illustration"],
            breadcrumbs: ["Authentication", "Sign Up", "Illustration"],
            nofooter: true,
        },
        component: () => import("../views/Authentication/Sign-Up/Illustration.vue"),
    },
    {
        path: "/layout",
        name: "Layout",
        layout: "dashboard",
        component: () => import("../views/Layout.vue"),
    },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
        return {
            x: 0,
            y: 0,
            behavior: "smooth",
        };
    },
});
// Helper function to check authentication and role
function isAuthenticated() {
    const accessToken = VueCookies.get("access_token");
    if (!accessToken) return false;

    try {
        const decodedToken = VueJwtDecode.decode(accessToken);
        return decodedToken;
    } catch (error) {
        console.error("Invalid token", error);
        return false;
    }
}

router.beforeEach((to, from, next) => {
    const decodedToken = isAuthenticated();
    const isLoginRoute = to.name === "Login";

    if (!decodedToken && !isLoginRoute) {
        // If not authenticated, redirect to login
        next({ name: "Login" });
    } else if (decodedToken && decodedToken.role === "admin" && to.name === "SomeRestrictedRoute") {
        // If user is admin and trying to access restricted route, block the navigation
        next({ name: "Home" }); // Replace 'Home' with a suitable default route
    } else if (decodedToken && isLoginRoute) {
        // If authenticated and trying to access login page, redirect to home
        next({ name: "Home" });
    } else {
        next(); // Proceed to the requested route
    }
});

export default router;
